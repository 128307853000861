<template>
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35.5" cy="35.5" r="35.5" fill="#D9D9D9"/>
      <path d="M36 26L45 34.5714M36 26L27 34.5714M36 26V46" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
  
<script>
export default {
  name: 'SendIcon0'
}
</script>
