import { apiClient } from './apiClient';

export const uploadBom = (file, bomType) => {  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bom_type', bomType); 
    //return apiClient.post('/uploadBom/uploadBom', formData);
    return apiClient.post('/uploadBom/uploadBom', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
