<template>
  <div id="home">
    <div class="header">
        <select class="dropdown-button" v-model="selectedBomType">
            <option disabled value="">Select your discipline</option>
            <option value="electronic">Electronic</option>
            <option value="mechanical">Mechanical</option>
        </select>
        <img :src="userAvatar" class="user-avatar" alt="User Avatar" @click="toggleDropdown" ref="avatarRef"/>
          <div v-show="showDropdown" class="user-dropdown" ref="dropdownRef">
              <ul>
                  <li><a href="#" @click="alertDevelopmentStatus" >My Plan</a></li>
                  <!-- <li><router-link to="/settings">Account Settings</router-link></li> -->
                  <li><a href="#" @click="alertDevelopmentStatus" >Account Settings</a></li>
                  <li><a href="#" @click="logOut">Log Out</a></li>
              </ul>
          </div>
    </div>
      
    <img :src="require('@/assets/logo.png')" class="logo-image" alt="Logo"/>

    <div class="query-container" ref="queryContainer" :class="{ 'no-scroll': !allowScroll }" @wheel="handleScroll">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-content">
          <div class="loading-circle"></div>
          <p>Loading, this may take a while...</p>
        </div>
      </div>
      
      <div v-if="showQueryImage" class="query-options-container">
        <div class="query-option" @click="focusInput">
          <img src="@/assets/image/chatSearch.png" class="option-icon" alt="Keyword Search"/>
          <div class="option-text keyword-text">Find product's price and availability by keyword, or by manufacturer part no.</div>
        </div>
        <div class="query-option" @click="triggerAndHandleFileUpload">
          <img src="@/assets/image/uploadFile.png" class="option-icon" alt="Excel Upload"/>
          <div class="option-text excel-text">Find product's price and availability by Excel upload</div>
        </div>
      </div>

      <div v-for="(message, index) in messages" :key="index" class="message" :class="{ 'few-messages': isFewMessages }">
          <div v-if="message.type === 'text'" class="user-message">
              {{ message.content }}
              <img v-if="message.isUser" :src="message.avatar" alt="User Avatar" class="mini-avatar" />
          </div>
          <!-- Separate the system message into its own div -->
          <div v-if="message.type === 'system'" class="system-message">
              <img v-if="!message.isUser" :src="message.logo" alt="Chat Logo" class="mini-logo" />
              <div v-html="message.content"></div>
          </div>

          <file-response 
              v-if="message.type === 'fileResponse'"
              :key="message.uniqueID" 
              :data="message.data" 
              @update:scroll="allowScroll = $event">
          </file-response>
      </div>
    </div>

    <div class="input-container">
        <input type="file" id="file-upload" ref="fileInput" @change="handleFileUpload" style="display: none;" />
        <div class="file-upload-label" @mouseover="hover = true" @mouseleave="hover = false" @click="triggerFileUpload">
            <img src="@/assets/image/vector_attach.png" class="attach-icon"/>
            <img v-show="hover" src="@/assets/image/upload_dis.png" class="upload-icon"/>
        </div>
        <input ref="newMessageInput" v-model="newMessage" placeholder="Find parts by typing or uploading your Excel or CSV" @input="checkInput" @keyup.enter="sendText"/>
        <component :is="currentIcon" @click="sendText" class="send-icon"/>
    </div>
  </div>
</template>

<script>
import { ref, nextTick, watch, computed, onMounted, onBeforeUnmount} from 'vue';
import { useRouter } from 'vue-router';
import FileResponse from '../components/FileResponse.vue';
import SendIcon0 from '../components/SendIcon0.vue';
import SendIcon1 from '../components/SendIcon1.vue';

import userAvatar from '@/assets/image/user_avatar.png'; 
import chatLogo from '@/assets/image/chat_logo.png';
// import { queryByChat } from '../api/queryByChat';
import {queryBySpec} from '../api/queryBySpec'
import { confirmQuery } from '@/api/confirmQuery';
import { uploadBom } from '@/api/uploadBom';
import Swal from 'sweetalert2';

export default {
  components: {
      SendIcon0,
      SendIcon1,
      FileResponse,
  },

  computed: {
        // Add caculate attribute to judge message arrary length 
        isFewMessages() {
          return this.messages.length <= 4; // Assume the little message is less than 4 
        }
      },

  methods: {
    triggerAndHandleFileUpload() {
      this.triggerFileUpload();
    },

    alertDevelopmentStatus() {
        Swal.fire({
          html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">This feature is currently under development. Please try again later.</span>',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'red-background-button'
          },
          buttonsStyling: false,
          width: '400px',
        });
      }
  },

  setup() {
      const newMessage = ref('');
      const messages = ref([]);
      const currentIcon = ref('SendIcon0');
      const hover = ref(false);
      const showQueryImage = ref(true); // Control query_option.png display
      const isLoading = ref(false);
      const incompleteSpecs = ref({});
      const fileInput = ref(null);

      const router = useRouter();

      const showDropdown = ref(false);
      const dropdownRef = ref(null);
      const avatarRef = ref(null);

      const selectedBomType = ref('');

      watch(selectedBomType, (newValue, oldValue) => {
        if (newValue === 'mechanical') {
          Swal.fire({
              title: 'Sorry!',
              html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Currently the system only supports electronic material BOM upload, mechanical is under development</span>',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'red-background-button'
              },
              buttonsStyling: false,
              width: '500px',
          }).then((result) => {
            if (result.isConfirmed) {
              selectedBomType.value = oldValue; // 将下拉框的值重置为默认值
            }
          });

        }
      });

      function toggleDropdown() {
          showDropdown.value = !showDropdown.value;
      }

      function handleClickOutside(event) {
          if (dropdownRef.value && !dropdownRef.value.contains(event.target) && 
              avatarRef.value && !avatarRef.value.contains(event.target)) {
              showDropdown.value = false;
          }
      }
      function focusInput() {
        showQueryImage.value = false
        messages.value.push({
              type: 'text', 
              content: "Can you help me find parts price and availability, by keyword?", 
              isUser: true,
              avatar: userAvatar  
          });
          messages.value.push({
              type: 'system', 
              content: "Of course, I'd love to help with that! To get started, can you please let me know what part you are looking for? For example, the part number, type of component or the component's characteristics?", 
              isUser: false,
              logo: chatLogo
          });
        /* this.$nextTick(() => {
          this.$refs.newMessageInput.focus(); // Focus on the text input
        }); */
      }

      onMounted(() => {
          document.addEventListener('click', handleClickOutside);
      });

      onBeforeUnmount(() => {
          document.removeEventListener('click', handleClickOutside);
      });

      watch(newMessage, (newValue) => {
          currentIcon.value = newValue.trim() ? 'SendIcon1' : 'SendIcon0';
      });

      function logOut() {
        // Clear local storage
       /*  localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
         */
        // Navigate to home page
        router.push({ name: 'Home' });
      }

      const fileResponses = computed(() => {
        return messages.value.filter(msg => msg.type === 'fileResponse');
      });

      async function sendText() {
          const userInput = newMessage.value.trim();
          // const sessionID = sessionStorage.getItem('sessionID') || 'default';

          if (!userInput) return;

          showQueryImage.value = false; // Hide query_option.png
          messages.value.push({
              type: 'text', 
              content: userInput, 
              isUser: true,
              avatar: userAvatar  
          });

          isLoading.value = true;  // Start display loading

          try {
              // const response = await queryBySpec(userInput, sessionID);
              const response = await queryBySpec(userInput);
              console.log('response headers :', response.headers);
              console.log('response data :', response.data);
              // Update session ID from response
              /* if (response.headers['x-session-id']) {
                  console.log('response x-session-id:', response.headers['x-session-id']);
                  this.sessionID = response.headers['x-session-id'];
                  sessionStorage.setItem('sessionID', response.headers['x-session-id']);
              } */
              handleQueryBySpecResponse(response);
          } catch (error) {
              isLoading.value = false;
              console.error('Chat query error:', error);
              messages.value.push({ type: 'system', content: "There some errors of the system, so I can't fetch data from server.", isUser: false, logo: chatLogo });
          } 

          newMessage.value = '';
          nextTick(() => {
            const container = document.querySelector(".query-container");
            if (container) {
                container.scrollTop = container.scrollHeight;
              }
            });
      }

      function handleQueryBySpecResponse(response) {
          // const { status, message, material_type, specs, missing_specs, provided_specs } = response;
          const { status, message, material_type, specs, provided_specs, number} = response.data;
          console.log('handleQueryBySpecResponse response.data:', response.data);
          console.log('handleQueryBySpecResponse material_type and status is :', material_type, status);
          console.log('handleQueryBySpecResponse provided_specs is :', provided_specs);
          switch (status) {
              case "irrelevant":
                  messages.value.push({ type: 'system', content: message, isUser: false, logo: chatLogo });
                  isLoading.value = false;
                  break;
              case "incomplete":
                  incompleteSpecs.value = provided_specs; // Store incomplete specs
                  console.log('stored incompleteSpecs string:', incompleteSpecs.value);
                  // console.log('incompleteSpecs is :', provided_specs);
                  messages.value.push({ type: 'system', content: message.replace(/\n/g, '<br><br>'), isUser: false, logo: chatLogo });
                  isLoading.value = false;
                  break;
              case "complete":
                  incompleteSpecs.value = ''; // Clear any stored specs
                  confirmUserQuery(material_type, specs, message, number); 
                  break;
          }
      }

      /* function highlightMissingSpecs(missing) {
          messages.value.push({ type: 'system', content: `Please provide the following missing specs: ${missing}`, isUser: false, logo: chatLogo });
      } */

      async function confirmUserQuery(material_type, specs, message, number) {
          
          console.log('confirmUserQuery material_type:', material_type)
          let keywordString = ''; 
          if (message === "SPEC") { 
              const keyword = Object.values(specs).filter(value => value !== "").join(",");
              keywordString = `${keyword},${material_type}`;
          } else if (message === "MPN") { 
              keywordString = specs;
          }
          console.log('confirmUserQuery string:', keywordString)
          console.log('confirmUserQuery number:', number)
          isLoading.value = true;
          try {
              const response = await confirmQuery(keywordString, number);
              console.log('Confirmation Response:', response);
              const results = response.data.data.map(supplier => `
                  <div class="supplier-detail" v-for="supplier in suppliers" :key="supplier.part_num">
                      <strong>Supplier:</strong> ${supplier.supplier_name}<br>
                      <strong>Price:</strong> £${supplier.unit_price}
                      (Break Quantity: ${supplier.qty}, minimum order quantity to get this price) <br>
                      <strong>Part Number:</strong> ${supplier.part_num}<br>
                      <strong>Available Quantity:</strong> ${supplier.available}<br>
                      <strong>Lead Time:</strong> ${supplier.lead_time} days (Estimated factory production time for quantities greater than available quantity shown) <br>
                      <strong>Location:</strong> ${supplier.location}<br>
                    
                      <strong>Purchase Link:</strong> <a href="${supplier.purchase_link}" target="_blank">${supplier.purchase_link}</a><br>
                      <strong>Datasheet:</strong> <a href="${supplier.datasheet_url}" target="_blank">${supplier.datasheet_url}</a>
                  </div><br>


              `).join("");

              let systemResponse = 'Sorry, the material information you are looking for is not found.'; 
              if (results){
                  // systemResponse = `The most effective and readity avaliable ${material_type} ${JSON.stringify(specs, null, 2)}: <br>${results}`;
                  systemResponse = `For ${material_type} with ${JSON.stringify(specs, null, 2)}, here are some options you can consider: <br>${results}`;
              }
              messages.value.push({
                  type: 'system', 
                  content: systemResponse, 
                  isUser: false,
                  logo: chatLogo
              });
          } catch (error) {
              console.error('Error in confirmQuery:', error);
              messages.value.push({
                type: 'system',
                content: 'Failed to fetch data from the server. Please try later or contact technicians for help.',
                isUser: false,
                logo: chatLogo
              });
          } finally {
              isLoading.value = false;  // After the request is completed, stop loading no matter success or fail
          }
      }

      function triggerFileUpload (){
          hover.value = false;

          if (!this.selectedBomType) {
            Swal.fire({
                html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Please select a BOM type first</span>',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'red-background-button'
                },
                buttonsStyling: false,
                width: '300px',
            });
            return;
          }

          console.log('File type:', this.selectedBomType);
          if (fileInput.value) {
              fileInput.value.value = ''; // Reset the file input
              fileInput.value.click(); // Open the file dialog
          }
      }

      async function handleFileUpload(event) {
          showQueryImage.value = false; // Hide query_option.png
          const file = event.target.files[0];
          console.log('File uploaded:', file.name);

          if (file && this.selectedBomType) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bom_type', this.selectedBomType);

            messages.value.push({
                  type: 'text', 
                  content: file.name, 
                  isUser: true,
                  avatar: userAvatar  
              });

            isLoading.value = true;  // Start display loading

            try {
                const response = await uploadBom(file, this.selectedBomType); 
                isLoading.value = false;

                console.log('---- Response processed file data', response.data.data)
                messages.value.push({
                    type: 'fileResponse',
                    isUser: false,
                    logo: chatLogo,
                    data: { items: response.data.data, message: 'File processed successfully.' }
                });

            } catch (error) {
                console.error('Upload failed', error);
                isLoading.value = false;
                messages.value.push({ type: 'text', content: "Failed to process file.", isUser: false });
                if (error.response) {
                  Swal.fire({
                      html: `<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Upload file failed: ${error.response.data.detail}</span>`,
                      confirmButtonText: 'OK',
                      customClass: {
                        confirmButton: 'red-background-button'
                      },
                      buttonsStyling: false,
                      width: '300px',
                  });
                    // alert('Upload failed: ' + error.response.data.detail);
                } else {
                    Swal.fire({
                        html: `<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Upload failed: Server not reachable</span>`,
                        confirmButtonText: 'OK',
                        customClass: {
                          confirmButton: 'red-background-button'
                        },
                        buttonsStyling: false,
                        width: '300px',
                    });
                    // alert('Upload failed: Server not reachable');
                }
            }
        }
      }

      function handleScroll(event) {
            const queryContainer = document.querySelector('.query-container');
            if (queryContainer) {
                // deltaY 属性提供了鼠标滚轮的垂直滚动量
                queryContainer.scrollTop += event.deltaY;
            }
        }


      return {
          fileInput,
          newMessage,
          messages,
          currentIcon,
          sendText,
          handleFileUpload,
          triggerFileUpload,
          hover,
          userAvatar,
          chatLogo,
          showQueryImage,
          selectedBomType,
          selectedFile : null,
          isLoading,
          fileResponses,
          handleScroll,
          avatarRef,
          dropdownRef,
          showDropdown,
          toggleDropdown,
          logOut,
          focusInput

      };
  }
};
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; 
    font-family: 'Segoe UI', Arial, sans-serif;
  }

  .no-scroll {
    overflow: hidden !important; /* Prohibat scroll */
  }
  
@media (max-width: 600px) {
  .few-messages {
    margin-bottom: 5px; 
  }
}

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

  .loading-content {
    text-align: center;
  }
  
  .loading-circle {
    border: 6px solid rgba(255, 204, 199, 0.6);
    border-top: 6px solid #D51900; /* Red part of the spinner */
    border-radius: 50%;
    width: 110px;
    height: 110px;
    animation: spin 1s linear infinite;
    align-items: center;
    margin: 0 auto;
    
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-content p {
    font-size: 18px;
    color: #666;
    margin-top: 20px;
  }

  .header {
    position: fixed; 
    z-index: 1000;
    top: 25px;
    width: 84%;
    left: 8%;
    display: flex;
    justify-content: space-between; /* Align elements on the left and right */
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .dropdown-button {
    width: 230px;
    height: 50px;
    border: 1px solid #D51900;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 100;
    background-color: white;
    padding-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('@/assets/image/vector_down.png') no-repeat right 10px center;
    background-size: 13px 6.5px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  .dropdown-button option {
    color: #000;
  }
  
  .user-avatar {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
  }

  .user-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    z-index: 100;
    right: 0;
    top: 70px;
  }

  .user-dropdown ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }

  .user-dropdown li {
      padding: 10px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
  }

  .user-dropdown li:last-child {
      border-bottom: none;
  }

  .user-dropdown li a {
      text-decoration: none;
      color: black;
      display: block;
  }

  .user-dropdown li:hover {
      background-color: #f9f9f9;
  }

  .logo-image {
    position: fixed;
    z-index: 1001;
    top: 70px;  /* Adjust specific top value to ensure not overlap with header */
    transform: translateX(-50%);
    left: 50%;
    width: 236px;
    height: 35px;
    margin: 0 auto;
  }

  .query-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    border: #ccc;
    top:120px;
    width: 86%;
    left: 7%;
    margin: 0 auto;
    height: calc(100vh - 230px);
    z-index:10;
  }

  .query-options-container {
    display: flex;
    justify-content: space-around;
    padding-top: 100px;
    width: 45%;
    height: 200px;
    margin: 0 auto;
  }

  .query-option {
    display: flex;
    flex-direction: column; /* Icon top, text bottom*/
    align-items: flex-start; /* Algin left */
    border: 1px solid #C3C8CF;
    padding: 20px;
    border-radius: 20px;
    width: 50%; 
    position: relative;
  }

  .query-option:first-child {
    margin-right: 50px; /* Add right margin to first child */
  }

  .query-option:hover::after {
    content: url('@/assets/image/tap-3.png');
    position: absolute;
    top: 80%;
    left: 80%;
    
    width: 10px; /* Try reducing this value if the image still appears large */
    height: auto; /* Maintains the aspect ratio */
    max-width: 10px; /* Ensures the image doesn't exceed the desired size */
    pointer-events: none;
    z-index: 10;
}

  .option-icon {
    width: 40px;
    height: 40px;
    align-self: left; 
    margin-bottom: 20px;
  }

  .option-text {
    text-align: left; 
    width: 100%; 
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    line-height: 1.7
  }


  .supplier-detail {
      margin-bottom: 10px;
      padding: 5px;
      background-color: #f4f4f4;
      border-radius: 8px;
  }

  .supplier-detail strong {
      color: #333;
  }

  .material-info {
      margin-bottom: 20px;
      font-weight: bold;
      color: #d51900;
  }

  .user-message {
    display: flex; 
    align-items: center;
    justify-content: flex-end;
    background-color: #F4F4F4;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-left: auto; 
    width: fit-content; 
    max-width: 75%;
    text-align: left;
    font-size: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;  /* Ensure that the line breaks at any character */
    line-height: 1.2;
    overflow: hidden;
  }

  .system-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 10px 15px;
    text-align: left;
    font-size: 18px; 
    width: fit-content;
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;  
    line-height: 1.2;
    overflow: hidden;
  }

  
  .mini-avatar {
    width: 30px;
    height: 30px;
    margin-left: 10px; /* Add spacing between message text and avatar */
  }
  
  .mini-logo {
    width: 30x;
    height: 20px;
    margin-right: 10px; /* Add spacing between logo and text */
  }

  .input-container {
    position: fixed;  
    display: flex;
    bottom: 20px;  
    width: 84%;  
    z-index: 1000;  /* Keep in top layer */
    background: #F4F4F4; 
    margin: 0 auto;
    border-radius: 50px;
    padding: 10px;
    margin-top: 20px;
    height: 60px; 
    align-items: center;
    justify-content: center;
    left: 7.5%;
  }

  input {
    flex-grow: 1;
    height: 50px;
    border: none;
    font-size: 18px;
    background-color: transparent;
    border-radius: 50px;
    margin-left: 10px;
    margin: auto;
    margin-left: 20px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  input:focus {
    outline: none;
    border: none;
  }
  
  input::placeholder {
    color: #A9A9A9;
  }
  
  .send-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5px;
    margin: auto;
  }
  
  .send-icon:focus {
    outline: none;
  }
  
  .attach-icon {
    width: 21.35px;
    height: 33px;
    cursor: pointer;
    margin-left: 25px;
  }
  
  .file-upload-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .attach-icon, .upload-icon {
    transition: opacity 0.3s;
    width: 21.35px;  /* Adjust size as needed */
    height: 33px;
  }
  
  .upload-icon {
    display: none; /* Hide by default and only show on hover */
    position: absolute; /* Overlay on top of attach-icon */
    width: auto;
    height: 120px;
    margin-left: 335px;
    margin-bottom: 120px;
  }
  
  .file-upload-label:hover .upload-icon {
    display: block;
  }
  </style>
  
  
  