import { apiClient } from './apiClient';

export const register = async (userData) => {
    const response = await apiClient.post('/login/register', {
        email: userData.email,
        password: userData.password
    }, {
        headers: {
            'Content-Type': 'application/json'  // 确保数据格式为JSON
        }
    });
    return response;
};
