import { createRouter, createWebHistory } from 'vue-router';
import UserLoginView from '@/views/UserLoginView.vue';
import ChatView from '@/views/ChatView.vue';
//import TestView from '@/views/TestView.vue';
//import ChatViewTest from '@/views/ChatViewTest.vue';

import UserSettings from '@/views/UserSettings.vue';
import RegisterView from '@/views/RegisterView.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home', // 这是你应该使用的路由名称，而不是 '/'
      component: UserLoginView
    },
    /* { path: '/', component:  ChatView}, */
    { path: '/login', component: UserLoginView },
    
    {
      path: '/chat',
      name: 'Chat',
      component: ChatView
    }, 
    {
      path: '/settings',
      name: 'Settings',
      component: UserSettings
    },
    {
      path: '/register',
      name: 'Register',
      component: RegisterView
    }
  ]
});

export default router;
