<template>
  <div class="login-container">
    <img :src="require('@/assets/logo.png')" class="logo-image" alt="Logo"/>
    <h2 class="account-title">Login</h2>
    <form @submit.prevent="submitForm">
      <div class="input-group">
        <input 
          type="email" 
          v-model="email" 
          placeholder="Email" 
          required 
          @input="validateInputs" 
        />
      </div>
      <div class="input-group-psw">
          <input 
            v-model="password" 
            :type="showPassword ? 'text' : 'password'" 
            placeholder="Password (at least 8 digits)" 
            required 
            @input="validateInputs"
          />
          <img 
            :src="showPassword ? require('@/assets/image/see.png') : require('@/assets/image/unsee.png')"
            @click="togglePasswordVisibility"
            class="toggle-password"
            alt="Toggle visibility"
          />
      </div>

      <div class="forgot-psw-link">
        <a href="#" @click="alertDevelopmentStatus" style="color: #D51900; font-family: Montserrat; font-weight: bold;">Forgot password?</a>
      </div>

      <button 
        type="submit" 
        class="btn-continue" 
        :disabled="!isFormValid" 
        :class="{ 'disabled-button': !isFormValid }"
      >
        Continue
      </button>

      <div class="register-link">
        <span class="text-account">Don't have an account? </span>
        <router-link to="/register" style="color: #D51900; font-family: Montserrat; font-weight: bold;"> Register</router-link>
      </div>

      <div class="separator">OR</div>
      <div class="social-buttons">
        <button @click="alertSocialLoginDevelopment($event)" class="btn-social">
          <img :src="require('@/assets/image/btnLoginGoogle4x.png')" alt="Continue with Google"/>
        </button>
        <button @click="alertSocialLoginDevelopment($event)" class="btn-social">
          <img :src="require('@/assets/image/btnLoginMicro4x.png')" alt="Continue with Microsoft"/>
        </button>
        <button @click="alertSocialLoginDevelopment($event)" class="btn-social">
          <img :src="require('@/assets/image/btnLoginApple4x.png')" alt="Continue with Apple"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { login } from '../api/login'; // Import the login function correctly
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      email: '',
      password: '',
      isFormValid: false,  // To control whether form is vaild 
      showPassword: false,
    };
  },
  methods: {
    validateInputs() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isFormValid = emailPattern.test(this.email) && this.password.length >= 8;
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;  // Switch the display status of password
    },

    alertDevelopmentStatus() {
      Swal.fire({
              html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Developing, please try later....</span>',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'red-background-button'
              },
              buttonsStyling: false,
              width: '400px',
          });
      },

    alertSocialLoginDevelopment(event) {
      event.preventDefault();
      Swal.fire({
        html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Feature under development. Please try later.</span>',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'red-background-button'
        },
        buttonsStyling: false,
        width: '300px',
      });
    },

    submitForm() {
      if (!this.isFormValid) return;  // If the form invaild, not submit 

      const userData = {
        email: this.email,
        password: this.password
      };

      login(userData).then(response => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.access_token);  // Save token to localStorage
          this.$router.push({ name: 'Chat' }); 
        } else {
          Swal.fire({
              html: '<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Login failed, please check your credentials.</span>',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'red-background-button'
              },
              buttonsStyling: false,
              width: '300px',
          });
        }
      }).catch(error => {
        console.error('Login error:', error);
        
        Swal.fire({
            html: `<span style="color: #000000; font-size: 16px; font-family: Montserrat;">${error.response.data.detail}</span>`,
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'red-background-button'
            },
            buttonsStyling: false,
            width: '300px',
        });
      });

    },

   /*  loginWithGoogle() {
      console.log('Login with Google');
    },
    loginWithMicrosoft() {
      console.log('Login with Microsoft');
    },
    loginWithApple() {
      console.log('Login with Apple');
    } */
  },
  mounted() {
    this.validateInputs(); // Init validation of form 
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.login-container {
  max-width: 514px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
}

.logo-image {
  width: 236px; 
  height: 35px;  
  margin-bottom: 20px; 
}

.account-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  height: 72px; 
  padding: 12px 20px;
  margin-bottom: 13px;
  border: 1px solid #3F3F3F;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 18px;
}

.input-group-psw {
  position: relative;
  display: flex;
  align-items: center;
}

.input-group-psw input {
  flex: 1;
  width: 100%;
  height: 72px; 
  padding: 12px 20px;
  margin-bottom: 13px;
  border: 1px solid #3F3F3F;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 18px;
}

.toggle-password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  height: 30px; 
}


.btn-continue {
  width: 100%;
  height: 72px;
  background-color: #D51900;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 13px;
}

.disabled-button {
  background-color: #cccccc;
  cursor: not-allowed;
}

.text-account {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.forgot-psw-link {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-left: 5px;
}

.register-link a {
  font-size: 16px;
  display: inline;
  font-weight: bold; 
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 24px;
  color: #000000;
  font-weight: bold;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #bbb;
  margin: 0 10px;
}

.social-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
}

.social-buttons button:last-child {
  margin-bottom: 0;
}

.social-buttons img {
  width: auto;
  max-width: 100%;
  height: auto;
}
</style>
