<template>
    <div class="user-settings">
      <h1>User Settings</h1>
      <div>
        <label for="avatar">Change Avatar:</label>
        <input type="file" id="avatar" @change="handleAvatarChange" />
      </div>
      <div>
        <label for="password">New Password:</label>
        <input type="password" id="password" v-model="newPassword" />
      </div>
      <div>
        <label for="confirmPassword">Confirm Password:</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" />
      </div>
      <button @click="updateSettings">Update Settings</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newPassword: '',
        confirmPassword: ''
      };
    },
    methods: {
      handleAvatarChange(event) {
        const file = event.target.files[0];
        this.uploadAvatar(file);
      },
      async uploadAvatar() {
        /* try {
          const formData = new FormData();
          formData.append('avatar', file);
          await axios.post('/api/upload-avatar', formData);
          alert('Avatar updated successfully!');
        } catch (error) {
          console.error('Error uploading avatar:', error);
          alert('Failed to update avatar.');
        } */
      },
      async updateSettings() {
        /* if (this.newPassword !== this.confirmPassword) {
          alert('Passwords do not match!');
          return;
        }
        try {
          const response = await axios.post('/api/update-password', {
            newPassword: this.newPassword
          });
          alert('Password updated successfully!');
        } catch (error) {
          console.error('Error updating password:', error);
          alert('Failed to update password.');
        } */
      }
    }
  };
  </script>
  
  <style scoped>
  .user-settings {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }
  </style>
  