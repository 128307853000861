import { apiClient } from './apiClient';
import qs from 'qs'; 

export const login = async (userData) => {
        const response = await apiClient.post('/login/token', qs.stringify({
            username: userData.email,
            password: userData.password
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response;
};
