import { apiClient } from './apiClient';

export const queryByChat = async (keywords) => {
    const response = await apiClient.post('/query/chat', { keywords }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};
