<template>
  <div class="file-response-container">
    <div class="query-result-container"
      @mouseenter="disableParentScroll" 
      @mouseleave="enableParentScroll">
      <div class="query-result">
        <table class="query-table">
          <thead>										 	 					
            <tr>
              <th v-for="header in tableHeaders" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in query_items" :key="index"
                @click="toggleSelectRow(item, index)"
                @mouseenter="handleMouseEnter(item, index)"
                @mouseleave="handleMouseLeave(index)"
                :class="{
                  'highlight-row': hoveredRowIndex === index,
                  'selected-row': selectedRowIndex === index
                }">
              <td v-for="(value, key) in item" :key="key">
                {{ value }}
              </td>
            </tr>

          </tbody>          

        </table>
      </div>

      <div class="export-button">
        <button @click="exportCSV"></button>
      </div>
    </div>

    <div class="detail-info" v-if="activeSuppliers.length > 0">
      <div class="price-trend" v-if="showPriceTrend">
        <img :src="require('@/assets/image/price_trend.png')" class="price-image" alt="price"/>
      </div>

      <div class="suppler-container" >
        <table class="supplier-table">
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Price/￡</th>
              <th>Available <br> Quantity</th>
              <th>Lead Tim<br>/Days</th>
              <th>Location</th>
              <th>Purchase Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="supplier in activeSuppliers" :key="supplier.name">
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.price }}</td>
              <td>{{ supplier.avaiblity }}</td>
              <td>{{ supplier.leadTime }}</td>
              <td>{{ supplier.location }}</td>
              <td>
                <a :href="supplier.purchaseLink" target="_blank" class="product-detail-link">
                  {{ supplier.purchaseLink }}
                </a>
              </td>

            </tr>

          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['data'],
  mounted() {
    if (this.data.items) {
      this.query_items = this.data.items;
    }
  },

  data() {
    return {
      totalMaterialCount: 167,  // Initial value set to 167
      totalSupplierCount: 430,  // Initial value set to 430

      query_items: this.data.items || [],
      activeSuppliers: [],
      highlightedRowIndex: null,
      hoveredRowIndex: null,
      selectedRowIndex: null,
      showPriceTrend: false,
    };
  },
  computed: {
    tableHeaders() {
      if (this.query_items.length > 0) {
        return Object.keys(this.query_items[0]); // 假设所有项具有相同的字段
      }
      return [];
    }
  },
  methods: {
    async loadTableData(url) {
      const response = await fetch(url);
      const data = await response.json();  
      this.query_items = data;  
    },

    disableParentScroll() {
      this.$emit('update:scroll', false); // Inform parent component not to scroll
    },
    enableParentScroll() {
      this.$emit('update:scroll', true); // Inform parent component to scroll
    },

    toggleSelectRow(item, index) {
      if (this.selectedRowIndex === index) {
        this.selectedRowIndex = null;
      } else {
        this.selectedRowIndex = index;
        this.activeSuppliers = this.extractSuppliersFromItem(item);
      }
    },
    handleMouseEnter(item, index) {
      if (this.selectedRowIndex === null) { 
        this.hoveredRowIndex = index;
        this.activeSuppliers = this.extractSuppliersFromItem(item);
      }
    },
    handleMouseLeave(index) {
      if (this.hoveredRowIndex === index && this.selectedRowIndex === null) {
        this.hoveredRowIndex = null;
        this.activeSuppliers = [];
      }
    },


    isRowHighlighted(index) {
      return index === this.highlightedRowIndex;
    },

    extractSuppliersFromItem(item) {
      const suppliers = [];
      for (let i = 1; i <= 3; i++) {
        const baseKey = `Supplier_${i}_`;
        if (item[baseKey + 'Name']) {
          suppliers.push({
            name: item[baseKey + 'Name'],
            price: item[baseKey + 'Price'],
            partNum: item[baseKey +'PartNumber'],
            avaiblity: item[baseKey + 'InStock'],
            leadTime: item[baseKey + 'Leadtime'],
            location: item[baseKey + 'Location'],
            purchaseLink: item[baseKey + 'PurchaseLink']
          });
        }
      }
      return suppliers;
    },

    async exportCSV() {
      const csvContent = this.generateCSVContent();
      if (this.isSafari()) {
        // For Safari，use traditional download link method
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'query_results.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        // For other browser, File System Access API
        try {
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const handle = await window.showSaveFilePicker({
            suggestedName: 'query_results.csv',
            types: [{
              description: 'CSV file',
              accept: {'text/csv': ['.csv']}
            }],
          });
          const writableStream = await handle.createWritable();
          await writableStream.write(blob);
          await writableStream.close();
          // alert('File saved successfully!');
          Swal.fire({
              html: `<span style="color: #000000; font-size: 16px; font-family: Montserrat;">File saved successfully!</span>`,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'red-background-button'
              },
              buttonsStyling: false,
              width: '300px',
          });
        } catch (err) {
          console.error('Error saving file:', err);
          // alert('Failed to save file.');
          Swal.fire({
              html: `<span style="color: #000000; font-size: 16px; font-family: Montserrat;">Failed to save file.</span>`,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'red-background-button'
              },
              buttonsStyling: false,
              width: '300px',
          });
        }
      }
    },

    generateCSVContent() {
      let csvContent = "";
      const headers = Object.keys(this.query_items[0]).map(header =>
        `"${header.replace(/"/g, '""')}"` // Replace internal "" 
      ).join(",");
      csvContent += headers + "\r\n"; // Add header and line break

      this.query_items.forEach(item => {
        let row = Object.values(item).map(field => {
          let value = String(field);
          if (value.search(/("|,|\n)/g) >= 0) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        }).join(",");
        csvContent += row + "\r\n"; // Add data and line break 
      });
      return csvContent;
    },

    isSafari() {
      // Use regular expression to check if userAgent is Safari
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.file-response-container {
  font-family: Arial, sans-serif;
  height: 650px;
}

.general-info {
  width: 86%;
  max-width: 1240px;
  height: 130px;
  display: flex;
  justify-content: space-around;
  margin: auto;
}

.dropdown-container {
  width: 97%;
  height: 47%;
  background-color: white;
  margin: auto;
  border-radius: 15px;
  margin-top: 15px;
}

.location-dropdown-button {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: 18px;
  padding-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('@/assets/image/vector_down.png') no-repeat right 10px center;
  background-size: 13px 6.5px;
  margin: auto;
}

.location-dropdown-button {
    color: #000;
}

.location-option, .material-count, .supplier-count {
  width: 32%;
  height: 100%;
  text-align: left;
  color: white;
  background-color: #D51900;
  border-radius: 20px;
}

.count-label {
  font-size: 24px; /* 24px font size for labels */
  font-weight: bold; /* Bold font weight */
  margin-top: 20px;
  margin-left: 30px;
}

.count-value {
  font-size: 40px; /* 40px font size for values */
  font-weight: bold; /* Bold font weight */
  margin-top: 15px;
  margin-left: 30px;
}

.query-result-container {
  width: 86%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
}

.query-result {
  overflow: hidden; /* hidden long content to ensure the border radius  */
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 20px;
  max-height: 300px; /* set a max height to ensure scroll  */
  width: 100%; 
}

.query-table {
  width: 100%; 
  border-collapse: collapse; 
  table-layout: fixed; 
}

/* Hover format */
.query-table tr:hover:not(.selected-row) {
  background-color: rgba(234, 205, 205, 0.915); 
}

.selected-row {
  background-color: rgba(222, 180, 180, 0.915); 
}

.query-table td {
  padding: 8px;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  border-bottom: 1px solid #FFA6A6; /* Maintain existing style */
  height: 30px; /* Fixed height for each row */
  width: 200px; /* Adjust width as necessary */
  border: 1px solid #ccc;
  text-align: center;
  border-left: hidden;
}

.query-table th:nth-child(1), .query-table td:nth-child(1) {
  width: 50px; /* the width of the first column  */
}

.query-table th:nth-child(2), .query-table td:nth-child(2) {
  width: 80px; /* the width of the second column */
}

.query-table th:nth-child(3), .query-table td:nth-child(3) {
  width: 100px; /*the width of the third column  */
}

.query-table th:nth-child(20), .query-table td:nth-child(20) {
  width: 300px; /*the width of the third column  */
}

.query-table th:nth-child(28), .query-table td:nth-child(28) {
  width: 300px; /*the width of the third column  */
}

.query-table th:nth-child(36), .query-table td:nth-child(36) {
  width: 300px; /*the width of the third column  */
}

.query-table th {
  background-color: white; /* Table header background color */
  border: none;
  text-align: center;
  border-bottom: hidden;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  width: 200px; /* Adjust width as necessary */
  padding: 8px;
  border-left: hidden;
}

.query-table thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.query-table tbody {
  background-color: rgba(255, 204, 199, 0.522);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
}
.query-table tr:first-child td:first-child {
  border-top-left-radius: 20px; 
}

.query-table tr:first-child td:last-child {
  border-top-right-radius: 20px; 
}

.query-table tr:last-child td {
  border-bottom: none; /* delete the border bottom of last row  */
}

.query-table td:last-child {
  border-right: none; /* delete the border right of last row  */
}

.export-button button {
  width: 170px; 
  height: 50px; 
  background: url('@/assets/image/export_file.png') no-repeat center / cover; /* Adjust path as necessary */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: transparent; /* Hides the button text */
  overflow: hidden; /* Prevents any overflow */
}

.export-button {
  margin-top: 10px;
  text-align: left;
}

.detail-info {
  width: 86%;
  height: 200px;
  justify-content: space-around;
  margin: 0 auto;
  display: flex;
  margin-top: 10px;
}

.price-trend {
  width: 40%;
  border-radius: 20px;
  display: flex;            /* Establishes a flex container */
  justify-content: center;  /* Centers content horizontally */
  align-items: center;      /* Centers content vertically */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
}

.price-image {
  width: 95%;
}

.suppler-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.supplier-table {
  width: 98%;
  height: 90%;
  border-collapse: collapse; 
  border-spacing: 0; 
  table-layout: fixed; 
  background-color: rgba(255, 204, 199, 0.522);
  border-radius: 20px;
}

.supplier-table td:last-child, .supplier-table th:last-child {
  width: 300px; /* set more width to column which have link */
}

.supplier-table th:nth-child(1), .supplier-table td:nth-child(1) {
  width: 300px; /*the width of the third column  */
}

.supplier-table th:nth-child(2), .supplier-table td:nth-child(2) {
  width: 300px; /*the width of the third column  */
}

.supplier-table th:nth-child(3), .supplier-table td:nth-child(3) {
  width: 90px; /*the width of the third column  */
}

.supplier-table th:nth-child(4), .supplier-table td:nth-child(4) {
  width: 90px; /*the width of the third column  */
}

.supplier-table th:nth-child(5), .supplier-table td:nth-child(5) {
  width: 80px; /*the width of the third column  */
}


/* Adjust width of other columns  */
.supplier-table th, .supplier-table td {
  padding: 8px;
  text-align: center;
  overflow: hidden; 
  text-overflow: ellipsis; /* display ...  */
  white-space: nowrap; /* prevent line break  */
}

/* add link format of Product Detail */
.product-detail-link {
  color: #0077cc; 
  text-decoration: none; 
  font-weight: bold; 
}

.product-detail-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.supplier-table td {
  padding: 8px;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  border-bottom: 1px solid #FFA6A6; /* Maintain existing style */
  height: 30px; /* Fixed height for each row */
  width: 20%; /* Adjust width as necessary */
  border: 1px solid #ccc;
  text-align: center;
  border-left: hidden;
}

.supplier-table th {
  background-color: white; 
  border: none;
  text-align: center;
  border-bottom: hidden;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  width: 20%; /* Adjust width as necessary */
  padding: 8px;
  border-left: hidden;
}

.supplier-table thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.supplier-table tbody {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
  border-radius:20px
}

.supplier-table tbody tr:first-child th:first-child {
  border-top-left-radius: 20px; /* border radius of top left cell  */
}

.supplier-table tbody tr:first-child th:last-child {
  border-top-right-radius: 20px; /* border radius of top right cell   */
}

.supplier-table tr:last-child td {
  border-bottom: none; 
}

.supplier-table td:last-child {
  border-right: none; 
}
</style>
