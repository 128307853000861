import { apiClient } from './apiClient';

export const confirmQuery = async (keywords, number) => {
    const response = await apiClient.post('/materials/confirmQuery', { keywords, number }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;  
};
