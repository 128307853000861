import { apiClient } from './apiClient';

export const queryBySpec = async (keywords) => {
    const response = await apiClient.post('/materials/queryBySpec', { keywords }, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return response;
};
